import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import IconButton from "@material-ui/core/IconButton"
import NavigateNextIcon from "@material-ui/icons/NavigateNext"
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore"

import ALink from "./ALink"
import { isLoggedIn } from "../../utils/auth"
import { addOrUpdateLearningProgressItem } from "../../api/learningProgress.service"

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    margin: "30px auto 0px auto",
    paddingBottom: "30px",
    [theme.breakpoints.up("sm")]: {
      width: "80vw",
    },
    [theme.breakpoints.up("lg")]: {
      width: "80vw",
    },
  },
  link: {
    textDecoration: "none",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& svg": {
      color: "#fff",
      width: "40px",
      height: "40px",
      background: `rgba(34,120,207,.7)`,
      borderRadius: "20px",
      margin: "0 10px",
      transition: "all 0.3s cubic-bezier(.25,.8,.25,1)",
      "&:hover": {
        transform: "translateY(-3px)",
      },
    },
    "& span": {
      margin: "0 5px",
    },
  },
}))

const Pagination = props => {
  const classes = useStyles()
  const { data, id } = props

  const handleClick = () => {
    if (isLoggedIn()) {
      addOrUpdateLearningProgressItem(data.id, data.topicId)
    }
  }
  const Next = ({ data, topic }) => {
    const classes = useStyles()
    const NextButton = ({ name, path }) => {
      return (
        <div className={classes.link}>
          <span>{name}</span>
          <ALink className={classes.link} to={path}>
            <IconButton onClick={handleClick}>
              <NavigateNextIcon />
            </IconButton>
          </ALink>
        </div>
      )
    }
    return data ? (
      <NextButton name="Bài sau" path={data} />
    ) : (
      <NextButton name="Kiểm tra" path={`/test/${topic}`} />
    )
  }

  const Prev = ({ data }) => {
    const classes = useStyles()
    const PrevButton = () => {
      return (
        <ALink className={classes.link} to={data}>
          <IconButton>
            <NavigateBeforeIcon />
          </IconButton>
          <span>Bài trước</span>
        </ALink>
      )
    }
    return data ? <PrevButton /> : " "
  }
  let prevId, nextId
  id.forEach((e, index) => {
    if (index === 0 && e === `${data.categoryId}/${data.id}`) {
      prevId = ""
      nextId = id[1]
    } else if (
      index === id.length - 1 &&
      e === `${data.categoryId}/${data.id}`
    ) {
      prevId = id[id.length - 2]
      nextId = ""
    } else if (
      index > 0 &&
      index < id.length - 1 &&
      e === `${data.categoryId}/${data.id}`
    ) {
      prevId = id[index - 1]
      nextId = id[index + 1]
    }
  })

  const prevPath = prevId ? `/topics/${data.topicId}/${prevId}` : ""
  const nextPath = nextId ? `/topics/${data.topicId}/${nextId}` : ""
  return (
    <Grid container className={classes.root}>
      <Grid container item xs={6} justify="flex-start">
        <Prev data={prevPath} />
      </Grid>
      <Grid container item xs={6} justify="flex-end">
        <Next data={nextPath} topic={data.topicId} />
      </Grid>
    </Grid>
  )
}
export default Pagination
