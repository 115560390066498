import React from "react"
import CardWithImage from "./CardWithImage"
import CardWithoutImage from "./CardWithoutImage"

const VocabularyItem = ({ data, lessionId }) => {
  return data.img.src !== "" ? (
    <CardWithImage data={data} />
  ) : (
    <CardWithoutImage data={data} lessionId={lessionId} />
  )
}
export default VocabularyItem
