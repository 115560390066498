import React from "react"
import Typography from "@material-ui/core/Typography"
import Breadcrumb from "../../common/Breadcrumb"
import ImgSlider from "../sections/BusinessManner/ImgSlider"
import CardbusinessManner from "../sections/BusinessManner/CardbusinessManner"
import VocabularyTable from "../sections/BusinessManner/VocabularyTable"
import Pagination from "../../common/Pagination"
import { makeStyles } from "@material-ui/core/styles"


const useStyles = makeStyles({
  root: {
    backgroundColor: "#f5f5f599",
  },
  DetailTitle: {
    color: " #3c64b1",
    paddingTop: "30px",
  },
  Styles: {
    marginBottom: "20px",
  },
  textContent: {
    marginBottom: "5px",
  },
})

// TODO: tạo component chung cho 2 ngôn ngữ
export default function SliderConversation({ data, id, isComponent }) {
  const classes = useStyles()
  return (
    <React.Fragment>
      {isComponent === false ? <Breadcrumb data={data} /> : null}
      <div className={classes.root}>
        <div className={classes.Styles}>
          <Typography
            className={classes.DetailTitle}
            variant="h5"
            align="center"
            gutterBottom
          >
            <div className={classes.textContent}>{data.title.vn}</div>
            <div className={classes.textContent}>{data.title.jp}</div>
          </Typography>
        </div>
        <div className={classes.Styles}>
          <CardbusinessManner data={data.Slider} />
        </div>
        <ImgSlider data={data.Slider} />
        <div className={classes.Styles}>
          <Typography
            className={classes.DetailTitle}
            variant="h5"
            align="center"
            gutterBottom
          >
            {data.Businessmanner.title.vn}
          </Typography>
        </div>
        <div className={classes.Styles}>
          <VocabularyTable data={data.Businessmanner} />
        </div>
        {isComponent === false ? <Pagination data={data} id={id} /> : null}
      </div>
    </React.Fragment>
  )
}
