import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { red } from "@material-ui/core/colors";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";


const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "90%",
    margin:"0 auto",
    [theme.breakpoints.up("sm")]: {
        maxWidth: "850px",
        width:"80%",
        margin:"0 auto" ,  
      },
  },
  icon:{
    padding:"0",
  },
  media: {
    height: 0,
    paddingTop: "56.25%" // 16:9
  },
  textContent:{
    lineHeight:"25px",
    [theme.breakpoints.up("sm")]: {
      lineHeight:"40px",
    },
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: "rotate(180deg)"
  },
  avatar: {
    backgroundColor: red[500]
  }
}));

export default function CardbusinessManner({data}) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card className={classes.root}>
      <CardContent>
      {data.description.jp.map((item,index) => (
        <Typography variant="body2" component="p" key ={index}>
          <div className={classes.textContent}>
            {item}
          </div>
        </Typography>
      ))}
      </CardContent>
      <CardActions className={classes.icon} disableSpacing>
        <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded
          })}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </IconButton>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
         {data.description.vn.map((item,index) => (
            <Typography paragraph key={index}>
               <div className={classes.textContent}>
                  {item}
               </div>
            </Typography>
          ))}
        </CardContent>
      </Collapse>
    </Card>
  );
}
