import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import Breadcrumb from "../../common/Breadcrumb"
import VocabularyItem from "../sections/Vocabulary/VocabularyItem"
import Pagination from "../../common/Pagination"
import Grid from "@material-ui/core/Grid"

const useStyles = makeStyles({
  main: {
    backgroundColor: "#f5f5f599",
  },
  root: {
    width: "80vw",
    margin: "0 auto",
  },
  text: {
    paddingTop: "30px",
  },
  card: {
    margin: "0 20px",
  },
})

const Vocabulary = ({ data, id, isComponent }) => {
  const classes = useStyles()
  return (
    <React.Fragment>
      {isComponent === false ? <Breadcrumb data={data} /> : null}
      <div className={classes.main}>
        <Typography
          variant="h5"
          align="center"
          gutterBottom
          className={classes.text}
        >
          {data.translation}
        </Typography>
        <Grid container justify="center" className={classes.root}>
          <Grid container item xs={12} lg={5} className={classes.card}>
            <Grid item xs={12}>
              {data.items.map((item, index) => {
                if (index < Object.keys(data.items).length / 2) {
                  return (
                    <Grid container item xs={12} key={index}>
                      <VocabularyItem data={item} lessionId={data.id} />
                    </Grid>
                  )
                } return null
              })}
            </Grid>
          </Grid>
          <Grid container item xs={12} lg={5} className={classes.card}>
            <Grid item xs={12}>
              {data.items.map((item, index) => {
                if (
                  index >= Object.keys(data.items).length / 2 &&
                  index < Object.keys(data.items).length
                ) {
                  return (
                    <Grid container item xs={12} key={index}>
                      <VocabularyItem data={item} lessionId={data.id} />
                    </Grid>
                  )
                } return null
              })}
            </Grid>
          </Grid>
        </Grid>
        {isComponent === false ? <Pagination data={data} id={id} /> : null}
      </div>
    </React.Fragment >
  )
}
export default Vocabulary
