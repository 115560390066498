import React from "react"
import withRoot from "../../../../components/shell/withRoot"
import Vocabulary from "../../../../components/learning/pageContents/Vocabulary"
import Conversation from "../../../../components/learning/pageContents/Conversation"
import BusinessManner from "../../../../components/learning/pageContents/SliderConversation"
import { graphql } from "gatsby"


function lessonPage({ params , data}) {
  const { topicId,lessonId, categoryId } = params
  const idStr = []
  let lessons = []
  const addLessonVocabulary = lessonArr => {
    for (let index = 0; index < lessonArr.length; index++) {
      lessons.push(lessonArr[index])
    }
  }
  let LessonData = []
  data.allVocabularyJson.nodes.forEach(vocabularyItem =>{
    vocabularyItem.items.forEach(item =>{
    if(item.img.src !=="" && !item.img.src.includes("vocabulary/")){
      item.img.src = "vocabulary/" + item.img.src
    }
    })
    LessonData.push(vocabularyItem)
  })
  // const addLessonConversation = lessonArr => {
  //   for (let index = 0; index < lessonArr.length; index++) {
  //     lessons.push(lessonArr[index])
  //   }
  // }
  addLessonVocabulary(LessonData)
  // addLessonConversation(data.allConversationJson.nodes)
  const lessonDatas = lessons.filter(i => i.topicId === topicId)
  lessonDatas.forEach(e => {
    idStr.push(`${e.categoryId}/${e.id}`)
  })
  if(categoryId && lessonId){
    switch (categoryId) {
      case "vocabulary":
        return lessonDatas ? (
          <Vocabulary
            data={lessonDatas.find(
              i => i.categoryId === categoryId && i.id === lessonId
            )}
            id={idStr}
            isComponent={false}
          />
        ): null
      case "conversation":
        return lessonDatas ? (
          <Conversation
            data={lessonDatas.find(
              i => i.categoryId === categoryId && i.id === lessonId
            )}
            id={idStr}
            isComponent={false}
          />
        ) : null
      case "slider-conversation":
        return lessonDatas ?(
          <BusinessManner
            data={lessonDatas.find(
              i => i.categoryId === categoryId && i.id === lessonId
            )}
            id={idStr}
            isComponent={false}
          />
        ) : null
      default:
        break
    }
  } else {
    return null
  }
  
}
export default withRoot(lessonPage)
export const query = graphql`
  {
    allVocabularyJson(sort: {fields: order}) {
      nodes {
        order
        id
        topicId
        breadcrumb
        categoryId
        items {
          vocabulary
          spelling
          meaning
          example
          translation
          img {
            alt
            src
          }
        }
      }
    }

  }
`
