import React from "react"
import Typography from "@material-ui/core/Typography"
import CardContent from "@material-ui/core/CardContent"
import { makeStyles } from "@material-ui/core/styles"
import Accordion from "@material-ui/core/Accordion"
import AccordionSummary from "@material-ui/core/AccordionSummary"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"

const useStyles = makeStyles(theme => ({
  content:{
    display: "flex",
    width: "100%",
    textAlign: "left",
    margin: "0 20px"
  },
  name:{
    minWidth: "15%"
  },
  text:{
    flex: "1",
    paddingRight: "30px"
  }

}))

export default function ConversationItem({ data }) {
  const classes = useStyles()
  return (
    <div>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
            <Typography variant="body2" align="center" gutterBottom>内容</Typography>
        </AccordionSummary>
        <CardContent>
          {
              data.jp.map((item, index) => {
                  return (
                    <div className={classes.content}>
                      <Typography key={index} className={classes.name} variant="body2" paragraph>
                          {item.name}
                      </Typography>
                      <Typography key={index} className={classes.text} variant="body2" paragraph>
                          {item.content}
                      </Typography>
                    </div>

                  )
              })
          }
        </CardContent>
  </Accordion>
  <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
          <Typography variant="body2" align="center" gutterBottom>Nội Dung</Typography>
      </AccordionSummary>
      <CardContent>
          {
              data.vn.map((item, index) => {
                  return (
                    <div className={classes.content}>
                      <Typography key={index} className={classes.name} variant="body2" paragraph>
                          {item.name}
                      </Typography>
                      <Typography key={index} className={classes.text} variant="body2" paragraph>
                          {item.content}
                      </Typography>
                    </div>

                  )
              })
          }
      </CardContent>
  </Accordion>
    </div>
  )
}
