import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import ConversationItem from "./ConversationItem"
import Audio from '../../../common/Audio';
import Image from '../../../common/Image';
import { Container } from "@material-ui/core";
import Card from '@material-ui/core/Card';

const useStyles = makeStyles(theme => ({
    root: {
        maxWidth: "90%",
        margin: "0% 15% ",
        [theme.breakpoints.down("sm")]: {
            maxWidth: "100%",
            margin: "3% 3% ",
        },
    },
    header: {
        backgroundColor: '#b9bcc1ee',
        position: "relative",
    },
    img: {
        height: 200,
        width: "100%",
        [theme.breakpoints.up("xs")]: {
            maxwidth: '70%',
            height: 250
        },
        [theme.breakpoints.up("sm")]: {
            width: '80%',
            height: 350,
        },
        [theme.breakpoints.up("md")]: {
            width: '80%',
            height: 350,
        },
        [theme.breakpoints.up("lg")]: {
            width: '60%',
            height: 300,
        },
    },
}))

export default function ConversationContent({ data }) {
  const classes = useStyles()
  return (
    <div>
        <Container>
            <Card className={classes.root}>
                <div className={classes.header}>
                    <center>
                        <Image className={classes.img} {...data.img} />
                        <Audio className={classes.audios} {...data.audio} />
                    </center>
                </div>
                <ConversationItem data={data.desciption} />
            </Card>
        </Container>
    </div>
  )
}
