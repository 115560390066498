import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TableHead from '@material-ui/core/TableHead';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "90%",
    margin: "0 auto",
    height: "250px",
    [theme.breakpoints.up("sm")]: {
      maxWidth: "850px",
      width: "80%",
      margin: "0 auto",
      height: "500px",
    },
  },
  table: {
    minWidth: 300,
  }
}));

export default function VocabularyTable({ data }) {
  const classes = useStyles();

  return (
    <TableContainer component={Paper} className={classes.root}>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow >
            <TableCell align="center">漢字</TableCell>
            <TableCell align="center">Furigana</TableCell>
            <TableCell align="center">Nghĩa</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.vocabularys.map((vocabulary, index) => (
            <TableRow key={index}  >
              <TableCell
                className={classes.cell}
                align="center">
                < div dangerouslySetInnerHTML={{ __html: vocabulary.key_word }}
                /> </TableCell>
              <TableCell
                className={classes.cell}
                align="center">{vocabulary.furigana}
              </TableCell>
              <TableCell
                className={classes.cell}
                align="center">{vocabulary.meaning}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
