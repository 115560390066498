import React from "react"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import MobileStepper from "@material-ui/core/MobileStepper"
import Button from "@material-ui/core/Button"
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft"
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight"

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: "90%",
    margin: "0 auto",
    borderRadius: "5px",
    backgroundColor: "white",
    border: "1px solid #c3c3c3",
    flexGrow: 1,
    [theme.breakpoints.up("sm")]: {
      maxWidth: "850px",
      width: "80%",
    },
  },
  img: {
    height: 200,
    maxWidth: "100%",
    margin: "0 auto",
    paddingTop: "10px",
    overflow: "hidden",
    display: "block",
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      maxWidth: "100%",
      height: 400,
    },
  },
  bnt: {
    maxWidth: "98%",
    margin: "0 auto",
  },
}))

export default function ImgSlider({ data }) {
  const classes = useStyles()
  const theme = useTheme()
  const [activeStep, setActiveStep] = React.useState(0)
  const maxSteps = data.description.image.length
  let imgSrcArr = []
  const imgPath = data.description.image.map(item => {
    imgSrcArr = item.src.relativePath.split("/")
    const fileName = imgSrcArr[imgSrcArr.length - 1]
    return {
      src: require("../../../../data/slider-conversation/media/" + fileName),
      alt: item.alt,
    }
  })
  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1)
  }
  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }
  return (
    <div className={classes.root}>
      <img
        className={classes.img}
        src={imgPath[activeStep].src}
        alt={imgPath[activeStep].alt}
      />
      <MobileStepper
        className={classes.bnt}
        steps={maxSteps}
        position="static"
        variant="text"
        activeStep={activeStep}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            Next
            {theme.direction === "rtl" ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === "rtl" ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            Back
          </Button>
        }
      />
    </div>
  )
}
