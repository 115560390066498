import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Card from "@material-ui/core/Card"
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import Image from '../../../common/Image'


const useStyles = makeStyles((theme) => ({
    root: {
        width: '80vw',
        margin: "40px auto",
        boxShadow: "4px 4px 15px 3px #D0D2D4",
        [theme.breakpoints.up('sm')]: {
            width: "60vw",
        },
        [theme.breakpoints.up('lg')]: {
            width: "80vw",
        },
    },
    image: {
        width: "100px",
        height: "100px",
    },
    cardcontent: {
        padding: "16px 0"
    }
}));

const WithImage = ({ data }) => {
    const classes = useStyles();
    const text = data.vocabulary
    const handleClick = e => {
        e.preventDefault();
        speechSynthesis.speak(new SpeechSynthesisUtterance(text))
    }
    return (
        <Card className={classes.root}>
            <Grid container>
                <Grid container item xs={5} justify="space-around" alignItems="center">
                    <Image className={classes.image} {...data.img} />
                </Grid>
                <Grid container item xs={5} justify="center" alignItems="center">
                    <CardContent className={classes.cardcontent} >
                        <Typography variant="body2" paragraph>
                            {data.vocabulary}
                        </Typography>
                        <Typography variant="body2" paragraph>
                            {data.meaning}
                        </Typography>
                    </CardContent>
                </Grid>
                <Grid container item xs={1} alignItems="center" direction="column" justify="space-around">
                    <IconButton
                        onClick={handleClick}>
                        <VolumeUpIcon />
                    </IconButton>
                </Grid>
            </Grid>
        </Card>
    )
}
export default WithImage
