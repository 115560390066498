import React, { useEffect } from "react"
import { makeStyles } from "@material-ui/core/styles"
import clsx from "clsx"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Collapse from "@material-ui/core/Collapse"
import Typography from "@material-ui/core/Typography"
import Grid from "@material-ui/core/Grid"
import IconButton from "@material-ui/core/IconButton"
import VolumeUpIcon from "@material-ui/icons/VolumeUp"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"

const useStyles = makeStyles(theme => ({
  root: {
    width: "80vw",
    margin: "30px auto 0 auto",
    boxShadow: "4px 4px 15px 3px #D0D2D4",
    [theme.breakpoints.up("sm")]: {
      width: "60vw",
    },
    [theme.breakpoints.up("lg")]: {
      width: "80vw",
    },
  },
  cardcontent:{
    padding:"16px 0"
  },
  image: {
    width: "100px",
    height: "100px",
  }
}))
const CardWithoutImage = ({ data, lessionId }) => {
  const classes = useStyles()
  const [expanded, setExpanded] = React.useState(false)
  useEffect(() => {
    setExpanded(false)
  }, [lessionId]);
  const handleExpandClick = (e) => {
    setExpanded(!expanded)

  }
  const text = data.vocabulary
  const handleClick = e => {
    e.preventDefault()
    const utterance = new SpeechSynthesisUtterance(text)
    const synth = window.speechSynthesis;
    const voicesList = synth.getVoices();
    utterance.voice = voicesList.find((voice) => voice.lang === 'ja-JP')

    utterance.lang = 'ja-JP'
    speechSynthesis.speak(utterance)
  }

  return (
    <Card className={classes.root}>
      <Grid container>
        <Grid container item xs={6} justify="space-around" alignItems="flex-start">
          <CardContent className={classes.cardcontent}>
            <Typography variant="body2" paragraph>
              {data.vocabulary}
            </Typography>
            <Typography variant="body2" paragraph>
              {data.spelling}
            </Typography>
          </CardContent>
        </Grid>
        <Grid container item xs={4} justify="space-around" alignItems="center">
          <CardContent className={classes.cardcontent}>
            <Typography className={classes.text} variant="body2" paragraph>
              {data.meaning}
            </Typography>
          </CardContent>
        </Grid>
        <Grid
          container
          item
          xs={2}
          alignItems="center"
          direction="column"
          justify="space-around"
        >
          <IconButton onClick={handleClick}>
            <VolumeUpIcon />
          </IconButton>
          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: expanded,
            })}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography variant="h6" paragraph>
            Ví dụ:
          </Typography>
          <Typography variant="body2" paragraph>
            {data.example}
          </Typography>
          <Typography variant="h6" paragraph>
            Dịch nghĩa:
          </Typography>
          <Typography variant="body2" paragraph>
            {data.translation}
          </Typography>
        </CardContent>
      </Collapse>
    </Card>
  )
}
export default CardWithoutImage
