import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Pagination from "../../common/Pagination";
import Breadcrumb from "../../common/Breadcrumb";
import ConversationContent from "../sections/Conversation/ConversationContent"

const useStyles = makeStyles((theme) => ({   
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    headerconversation: {
        padding: 15,
        color: " #3c64b1",
        [theme.breakpoints.down("sm")]: {
            padding: 5,
        },
    },
    main: {
        background: "#f5f5f599",
    },
}));

const Conversation = ({ data, id, isComponent }) => {
    const classes = useStyles();
    return (
        <React.Fragment>
            {isComponent === false ? <Breadcrumb data={data} /> : null}
            <div className={classes.main} >
                <div className={classes.headerconversation}>
                    <Typography variant="h5" align="center" gutterBottom>
                        {data.title.vn}
                    </Typography>
                    <Typography variant="h5" align="center" gutterBottom>
                        {data.title.jp}
                    </Typography>
                </div>
                <ConversationContent data = {data.conversation}/>
                {isComponent === false ? <Pagination data={data} id={id} /> : null}
            </div>
        </React.Fragment>
    )
}
export default Conversation;